@import-normalize;

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  background-color: #f2f2f2;
}

ul,
li {
  list-style: none;
}
